<template>
  <b-modal
    ref="previewDocumentModal"
    size="lg"
    hide-footer
    ok-only
    centered
    no-close-on-esc
    no-close-on-backdrop
    modal-class="cp-preview-modal"
    :title="model.title"
    @close="backToList"
    @ok="backToList"
    @show="showModal"
    @shown="previewDocument"
  >
    <img
      v-if="previewImage"
      class="d-inline-block"
      :src="previewImage"
      alt="Document Preview"
    >
    <iframe
      v-if="previewHtml"
      id="preview-kyc-iframe"
      :src="previewHtml"
      frameborder="0"
    />
    <Spinner
      v-if="!previewImage && !previewHtml"
      name="wave"
      color="#04bec4"
      class="cp-button-spinner"
      fade-in="quarter"
    />
  </b-modal>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Spinner from 'vue-spinkit';

export default {
  name: 'ViewOpportunityDocumentModal',
  metaInfo: {
    title: 'View opportunity',
  },
  components: {
    Spinner,
  },
  data() {
    return {
      isReady: false,
      model: {},
      previewImage: '',
      previewHtml: '',
    };
  },
  computed: {
    ...mapGetters('opportunities', ['currentOpportunity']),
  },
  // async created() {
  //   this.$refs.previewDocumentModal.show();
  //   this.previewDocument();
  // },
  mounted() {
    this.showModal();
  },
  methods: {
    ...mapActions('opportunities', ['getOpportunityDocument']),
    onEditActivated(type) {
      return type !== 'details' && this.$refs.details.cancelIfActive();
    },
    backToList(env) {
      env.preventDefault();
      this.$router.push({ name: 'OpportunityDocumentsMain' });
    },
    showModal() {
      this.$refs.previewDocumentModal.show();
    },
    async previewDocument() {
      this.isReady = false;
      const { idIssuer: issuerId, docId: opportunityDocumentId } = this.$route.params;
      this.model = await this.getOpportunityDocument({ issuerId, opportunityId: this.currentOpportunity.id, opportunityDocumentId });
      const url = this.model.temporaryUrl;
      if (this.model.fileType !== 'application/pdf') {
        this.previewImage = url;
      } else {
        this.previewHtml = url;
      }
      this.isReady = true;
    },
  },
};
</script>
<style lang="scss" scoped>
  .cp-preview-modal{
    text-align: center;
    img{
      max-width:100%;
    }
    iframe{
      border:0;
      width:100%;
      height:700px;
    }
  }
  .cp-button-spinner{
    width: 100%;
    height: 20px;
    position: absolute;
    margin: auto;
    background: rgba(255,255,255,1);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.6;
  }
  img.link{
    cursor: pointer;
  }
</style>
